import axios from 'axios';

export default class OrigenService {

	getOrigen() {
		const url = `/api/origen`;
		return axios.get(url).then(res=>res.data.data);
	}

	createOrigen(origen) {
		const url = `/api/origen`;		
		return axios.post(url,origen).then(res=>res.data.data);
	}
	
	updateOrigen(origen) {
		const url = `/api/origen/${origen.id_origen}`;
		return axios.put(url,origen).then(res=>res.data.data);
	}
	
	deleteOrigen(origen) {
		const url = `/api/origen/${origen.id_origen}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}