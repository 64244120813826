<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Origen..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedEstados || !selectedEstados.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="origenes" v-model:selection="selectedEstados" 
				:key="estadoKey" dataKey="clave_estado" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords}">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Origen</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="nombre" header="Origen" :sortable="true"></Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editOrigen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteOrigen(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="origenDialog" :style="{width: '450px'}" header="Origen" :modal="true" class="p-fluid">
					
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="origen">Origen *</label>
							<InputText id="origen" v-model="origen.nombre" required="true" autofocus :class="{'p-invalid': submitted && !origen.nombre}" />
							<small class="p-invalid" v-if="submitted && !origen.nombre">El origen es requerido</small>
						</div>

                    	<div class="p-field-checkbox p-col">
                    	    <Checkbox id="activo" name="activo"  :binary="true" v-model="origen.activo" />
                    	    <label for="activo">Activo</label>
                    	</div>
					</div>

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveOrigen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteOrigenDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="origen">¿Desea eliminar el origen <b>{{origen.nombre}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrigenDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteOrigen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteOrigenesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="estado">¿Desea eliminar los origenes seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteOrigenDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedOrigen" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import OrigenService from '../service/OrigenService';
import XLSX from 'xlsx'
export default {
	data() {
		return {
			estadoKey: 0,
			origenes: null,
			origenDialog: false,
			deleteOrigenDialog: false,
			deleteOrigenesDialog: false,
			origen: {},
			origenactivo: false,
			selectedOrigenes: null,
			filters: {},
			submitted: false,
			OrigenSub:[],
			id_origen:null,
			activo:null,
			origennombre:null,
			OrigenesExpor:null,
		}
	},
	origenService: null,
	created() {
		this.origenService = new OrigenService();
	},
	mounted() {
		this.origenService.getOrigen().then(data => this.origenes = data);
	
	},
	methods: {
		openNew() {
			this.origen = {};
			this.submitted = false;
			this.origenDialog = true;
			this.origen.activo=true;
		},
		hideDialog() {
			this.origenDialog = false;
			this.submitted = false;
		},
		saveOrigen() {
			this.submitted = true;
           
			if (this.origen.nombre.trim()) {
				if (this.origen.id_origen>0) {

					this.OrigenSub.push({
						"id_origen":this.origen.id_origen,
						"activo":this.origen.activo,
						"nombre":this.origen.nombre,
					});
				 
					this.origenService.updateOrigen(this.OrigenSub[0]).then();
					
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Origen actualizado', life: 3000});
					this.OrigenSub=[];					
					document.location.reload();
					 
				}
				else {
					this.OrigenSub.push({
						"activo":this.origen.activo,
						"nombre":this.origen.nombre,
					});
					 
					this.origenService.createOrigen(this.OrigenSub[0]).then();
					
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Origen creado', life: 3000});
					this.OrigenSub=[];			
					document.location.reload();
					 
				}

				this.origenDialog = false;
				this.origen = {};
			}
		},
		editOrigen(origen) {
			this.origen = {...origen};		
			this.origenDialog = true; 
		},
		confirmDeleteOrigen(origen) {
			this.origen = origen;
			this.deleteOrigenDialog = true;
		},
		deleteOrigen() {
			this.OrigenSub.push({
				"id_origen":this.origen.id_origen,
				"activo":0,
				"nombre":this.origen.nombre,
			});
				 
			this.origenService.updateOrigen(this.OrigenSub[0]).then()
			// this.origenService.deleteOrigen(this.origen).then();
			this.deleteOrigenDialog = false;
			 				
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Origen eliminado', life: 3000});
			document.location.reload();
			 
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.origenes.length; i++) {
				if (this.origenes[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteOrigenesDialog = true;
		},
		deleteSelectedOrigenes() {
			this.origenes = this.origenes.filter(val => !this.selectedOrigenes.includes(val));
			console.log(this.origenes);
			this.deleteOrigenesDialog = false;
			this.selectedOrigenes = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Origenes eliminados', life: 3000});
		},
		turno(value){
			if(value ==true){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		download : function() {
			this.origenService.getOrigen().then(data => {
			this.OrigenesExpor = data
				if(this.OrigenesExpor != null){
				const data = XLSX.utils.json_to_sheet(this.OrigenesExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoOrigen.xlsx')
			}
			});
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.estado-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .estado-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.estado-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
